declare var window: any;

export interface Configuration {
  DEBUG: boolean;
  API_HOST: string;
  WS_HOST: string;
  DOC_HOST: string;
}

const config: Configuration = {
  DEBUG: window.CF__DEBUG__ === 'true',
  API_HOST: window.CF__API_HOST__,
  WS_HOST: window.CF__WS_HOST__,
  DOC_HOST: window.CF__DOCS_URL__ || '/',
};

export default config;
